import React from 'react';
import ChatBot from './components/ChatBot';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3434cb',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
        <ChatBot />
    </ThemeProvider>
  );
}

export default App;