import React, { useState, useEffect } from 'react';
import ChatMessage from './ChatMessage';
import ChatInput from './ChatInput';
import BackgroundCharts from './BackgroundCharts'
import '../styles/ChatBot.scss';
import sendRequest from '../helpers/send-request'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Switch} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import CloseIcon from '@mui/icons-material/Close';
import Dragable from './Dragable';

const ChatBot = () => {
  const initialChartsData = {
    linechart: {"title":"Production Trend for <a href='well/permian_east_10'>Permian East 10</a> and <a href='well/permian_east_5'>Permian East 5</a>","yAxisTitle":"Production (Mscf/d)","xAxisTitle":"Days","pointStart":700,"series":[{"name":"<a href='well/permian_east_10'>Permian East 10</a>","data":[351,345,337,337,343,334,340,345,332,343,351,331,341,331,345,348,343,344,349,332,328,336,347,337,330,344,328,335,345,344,336]},{"name":"<a href='well/permian_east_5'>Permian East 5</a>","data":[292,299,298,282,297,297,285,283,292,294,288,296,282,294,292,285,283,289,286,283,286,283,278,285,286,288,276,278,275,290,290]}]},
    table: [["Well","Underperformance (Mscf)"],["<a href='well/permian_east_13'>Permian East 13</a>",132],["<a href='well/permian_east_12'>Permian East 12</a>",60],["<a href='well/permian_east_14'>Permian East 14</a>",57],["<a href='well/permian_east_11'>Permian East 11</a>",29],["<a href='well/permian_east_7'>Permian East 7</a>",25],["<a href='well/permian_east_4'>Permian East 4</a>",15],["<a href='well/permian_east_3'>Permian East 3</a>",11],["<a href='well/permian_east_9'>Permian East 9</a>",5]],
    barchart: {"title":"Production Comparison: <a href='well/permian_east_5'>Permian East 5</a> vs <a href='well/permian_east_10'>Permian East 10</a>","yAxisTitle":"Production (Mscf/d)","xAxisTitle":"Days","series":[{"name":"<a href='well/permian_east_5'>Permian East 5</a>","data":[292,299,298,282,297,297,285,283,292,294,288,296,282,294,292,285,283,289,286,283,286,283,278,285,286,288,276,278,275,290,290]},{"name":"<a href='well/permian_east_10'>Permian East 10</a>","data":[351,345,337,337,343,334,340,345,332,343,351,331,341,331,345,348,343,344,349,332,328,336,347,337,330,344,328,335,345,344,336]}],"categories":["Day 1","Day 2","Day 3","Day 4","Day 5","Day 6","Day 7","Day 8","Day 9","Day 10","Day 11","Day 12","Day 13","Day 14","Day 15","Day 16","Day 17","Day 18","Day 19","Day 20","Day 21","Day 22","Day 23","Day 24","Day 25","Day 26","Day 27","Day 28","Day 29","Day 30","Day 31"]},
  }

  const [chartsData, setChartsData] = useState(initialChartsData);
  const [isLoading, setIsLoading] = useState(false);
  const [showSQL, setShowSQL] = useState(false);

  const handleShowSQL = (event) => {
    setShowSQL(event.target.checked);
  };

  const [open, setOpen] = useState(true);
  const [maximized, setMaximized] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMaximized(false);
  };

  const handleMinimize = () => {
    setMaximized(false);
  };

  const handleMaximize = () => {
    setMaximized(true);
  };


  const [messages, setMessages] = useState(() => {
    const storedMessages = localStorage.getItem('chatMessages');
    return storedMessages ? JSON.parse(storedMessages) : [];
  });

  useEffect(() => {
    localStorage.setItem('chatMessages', JSON.stringify(messages));
  }, [messages]);

  const handleSendMessage = async (message) => {
    const userMessage = {role: 'user', content: message};
    setIsLoading(true);
    const messageHistory = [...messages, userMessage]
    setMessages(messageHistory);

    const writeResponse = (llmResponse) => {
      console.log(llmResponse);
      if (llmResponse.form) {
        setChartsData({...chartsData, [llmResponse.form]: llmResponse.data})
      }
      setMessages(() => [...messageHistory, { 
        role: 'assistant',
        ...llmResponse
      }])
    }
    const stopLoading = () => {
      setIsLoading(false);
    }

    sendRequest(message, writeResponse, stopLoading);
  };

  const handleClearChatHistory = () => {
    localStorage.clear();
    setMessages([])
  }
  return (
    <main className="main-container">
      <BackgroundCharts chartsData={chartsData}/>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        className='modal-button'
      >
        <ChatIcon/>
      </Button>

      <Dialog
        open={open} 
        onClose={handleClose} 
        PaperComponent={Dragable} 
        className='modal-container'       
        fullScreen={maximized}
      >
        <DialogTitle id="draggable-dialog-title" className='modal-title'>
          <span>
            Production Assistant
          </span>
          <div>
            {maximized ? <IconButton onClick={handleMinimize} size="small" className='header-button'>
              <CloseFullscreenIcon />
            </IconButton> :
            <IconButton onClick={handleMaximize} size="small" className='header-button'>
              <OpenInFullIcon />
            </IconButton>}
            <IconButton onClick={handleClose} size="small" className='header-button'>
              <CloseIcon/>
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="chat-messages">
          <div>
            <Switch
              checked={showSQL}
              onChange={handleShowSQL}
              color="primary"
              inputProps={{ 'aria-label': 'toggle button' }}
            />
          Show Sql
          </div>
            {messages.map((msg, index) => (
              <ChatMessage key={index} message={msg} showSQL={showSQL}/>
            ))}
        </DialogContent>
        <DialogActions className="chat-actions">
          <ChatInput onSendMessage={handleSendMessage} onClearChatHistory={handleClearChatHistory} isLoading={isLoading}/>
        </DialogActions>
      </Dialog>
    </main>
  );
};

export default ChatBot;