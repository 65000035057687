import React from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport  } from '@mui/x-data-grid';
import '../styles/TableGraph.scss';


const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const TableGraph = ({ data, className }) => {
  const rows = data.slice(1).map((row, index) => {
    const rowData = { id: index };
    row.forEach((value, i) => {
      rowData[`col${i}`] = value;
    });
    return rowData;
  });
  
  const columns = data[0].map((header, index) => ({
    field: `col${index}`,
    headerName: header,
    width: 200,
    renderCell: (params) => (
      <div dangerouslySetInnerHTML={{ __html: params.value }} />
    ),
  }));

  return (
    <div className={`table-container ${className}`}>
      <DataGrid 
        rows={rows}
        columns={columns} 
        slots={{
          toolbar: CustomToolbar,
        }}
       />
    </div>
  );
}

export default TableGraph;