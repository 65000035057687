import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import '../styles/CardItem.scss';

import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';


const CardItem = ({title, value, textValue, color}) => {
  return (
    <Card className='card-item-container'>
        <Typography variant="h6" component="div" gutterBottom className='card-item-title'>
         {title}
        </Typography>
      <Gauge 
        width={220} 
        height={135} 
        value={value}
        startAngle={-110}
        endAngle={110} 
        text={textValue}
        color={color}
        sx={(theme) => ({
          [`& .${gaugeClasses.valueText}`]: {
            fill: color,
          },
          [`& .${gaugeClasses.valueArc}`]: {
            fill: color,
          },
        })}
        />
    </Card>
  );
}

export default CardItem;