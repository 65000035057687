import React, { useState } from 'react';
import { TextField, IconButton, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AutoDelete from '@mui/icons-material/AutoDelete';
import '../styles/ChatInput.scss';

const ChatInput = ({ onSendMessage, onClearChatHistory, isLoading }) => {
  const [input, setInput] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) {
      onSendMessage(input);
      setInput('');
    }
  };

  const handleClearChatHistory = () => {
    onClearChatHistory();
  };

  return (
    <form onSubmit={handleSubmit} className="chat-input">
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Type your message here..."
        value={input}
        onChange={(e) => setInput(e.target.value)}
        className="chat-text-field"
      />
      <IconButton 
        type="submit" 
        className={`send-button ${isLoading ? 'loading' : ''}`} 
        disabled={isLoading || !input.trim()}
         size="small"
      >
        {isLoading ? <CircularProgress size={24} color="inherit" /> : <SendIcon />}
      </IconButton>
      <IconButton onClick={handleClearChatHistory} className="clear-button" size="small">
        <AutoDelete />
      </IconButton>
    </form>
  );
};

export default ChatInput;