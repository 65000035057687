import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Typography } from '@mui/material';

const BarChartGraph = ({ data, className }) => {
  const xAxis = [{ scaleType: 'band', data: data.categories, label: data.xAxisTitle }];
  const yAxis = [
    {
      label: data.yAxisTitle
    }
  ]
  const series = data.series.map(item => ({
    name: item.name,
    data: item.data,
  }));

  return (
    <div className={className ? `${className} chart-container` : 'chart-container'}>
      <Typography variant="h6" component="h2" height={60} className='chart-title'>
        <div dangerouslySetInnerHTML={{ __html: data.title }} />
      </Typography>
      <BarChart
        yAxis={yAxis}
        xAxis={xAxis}
        series={series}
        width={440}
        height={300}
      />
    </div>
  );
};

export default BarChartGraph;