import React from 'react';
import LineChartGraph from './LineChartGraph';
import BarChartGraph from './BarChartGraph';
import TableGraph from './TableGraph';
import CardItem from './CardItem';
import '../styles/BackgroundCharts.scss';


const ChatMessage = ({chartsData}) => {
  const {linechart, barchart, table} = chartsData
  return (
    <div className='bachground-charts-container'>
      <div className='bachground-charts-column'>
        <LineChartGraph data={linechart} className='bachground-chart-items'/>
        <BarChartGraph data={barchart} className='bachground-chart-items'/>
      </div>
      <div className='bachground-charts-column'>
        <div className='bachground-charts-row'>
          <CardItem title='# Wells' textValue='193' value='14' color='#02B2AF'/>
          <CardItem title='Oil Production/Month' textValue='55' value='45' color='#2E96FF'/>
          {/* <CardItem title='Projected Production' textValue='110 B' value='85' color='rgb(252, 121, 98)'/> */}
        </div>
       <TableGraph data={table} className='bachground-chart-items'/>
      </div>
      <div className='bachground-charts-column'>
        <LineChartGraph data={linechart} className='bachground-chart-items'/>
        <BarChartGraph data={barchart} className='bachground-chart-items'/>
      </div>
    </div>
  );
};

export default ChatMessage;