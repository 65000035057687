import React, { useState } from 'react';
import { Paper, Typography, IconButton, Collapse, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import Additionalnfo from './AdditionalInfo';
import LineChartGraph from './LineChartGraph';
import BarChartGraph from './BarChartGraph';
import TableGraph from './TableGraph';
import '../styles/ChatMessage.scss';

const ChatMessage = ({ message, showSQL }) => {
  const [expanded, setExpanded] = useState(false);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleLike = () => {
    setLiked(false);
    // setLiked(!liked);
    // setDisliked(false);
  };

  const handleDislike = () => {
    setDisliked(false);
    // setDisliked(!disliked);
    // setLiked(false);
  };
  const {content, data, form, sqlQuery, role} = message

  const isUser = role === 'user';

  return (
    <Paper className={`message ${isUser ? 'user' : 'assistant'}`}>
      <div className="message-container">
        <div className="message-header">
          <Typography variant="body2" className="message-sender">
            <span>
              {isUser ? 'You: ' : 'Bot:'}
            </span>
            
          </Typography>
        </div>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      {
        form === "linechart" && data && <LineChartGraph data={data}/>
      }
      {
        form === "barchart" && data && <BarChartGraph data={data}/>
      }
      {
        form === "table" && data && <TableGraph data={data}/>
      }
      {showSQL && sqlQuery && (<div className='debug-info-container'>
        <div className='debug-info-action'>
        <span>Show SQL</span> <IconButton
              className={`expand-button ${expanded ? 'expanded' : ''}`}
              onClick={toggleExpand}
              size="small"
            >
              <ExpandMoreIcon />
            </IconButton>
        </div>
              <Collapse in={expanded}>
                <Additionalnfo sqlQuery={sqlQuery}/>
              </Collapse>
      </div>)}
      {!isUser && (
        <div className="feedback-buttons">
          <Tooltip title="Comming soon" arrow>
            <IconButton onClick={handleLike} color={liked ? "primary" : "default"} >
              <ThumbUpIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Comming soon" arrow>
            <IconButton onClick={handleDislike} color={disliked ? "primary" : "default"} >
              <ThumbDownIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </Paper>
  );
};

export default ChatMessage;