import React from 'react';
import { 
  Paper 
} from '@mui/material';
import '../styles/AdditionalInfo.scss';

const AdditionalInfo = ({ sqlQuery }) => {
  return (
    <div className="togglable-list-container">
      {sqlQuery}
    </div>
  );
};

export default AdditionalInfo;