import React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { Typography } from '@mui/material';
import '../styles/LineChartGraph.scss';

const LineChartGraph = ({ data, className }) => {
  const series = data.series.map(series => {
      return {
        data: series.data,
      }
  })
  const xAxis = [
    {
      data: Array.from({ length: data.series[0].data.length }, (_, index) => data.pointStart + index),
      label: data.xAxisTitle
    }
  ]

  const yAxis = [
    {
      label: data.yAxisTitle
    }
  ]

  return (
    <div className={className ? `${className} chart-container` : 'chart-container'}>
      <Typography variant="h6" component="h2" height={60} className='chart-title'>
        <div dangerouslySetInnerHTML={{ __html: data.title }} />
      </Typography>
      <LineChart
        yAxis={yAxis}
        xAxis={xAxis}
        series={series}
        width={440}
        height={300}
      />
    </div>
  );
};

export default LineChartGraph;