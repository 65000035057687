const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // eslint-disable-next-line no-mixed-operators
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}

const sendRequest = async (message, writeResponse, stopLoading) => {
  try {
    const url = window.API_IP_ADDRESS && process.env.API_PORT ? `${window.API_IP_ADDRESS}:${process.env.API_PORT}` : `${process.env.REACT_APP_API_KEY || 'http://127.0.0.1:8080'}/api/v1/query/`;
    const session_id = localStorage.getItem('session_id') || generateUUID();
    localStorage.setItem('session_id', session_id);

    const data = {
      query: message
    };
    let response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        'session-id': session_id
      },
      body: JSON.stringify(data),
    });

    if (!response.ok || !response.body) {
      console.log("Something went wrong!");
    } else {
      response = await response.json();
      const {message, data, disclaimer, form, sql_query: sqlQuery} = response;
      writeResponse({content: message, data, disclaimer, form, sqlQuery});
    }
  } catch(error) {
    console.log(error);
  } finally {
    stopLoading();
  }
}

  export default sendRequest;
